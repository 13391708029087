import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  ISitemateAssignedUserWithTaskUrls,
  ISitemateTaskDefinitionUpdate,
  ISitemateTaskDefinitionUrl,
  ISitemateTaskDefinitionWarning,
  SitemateRecordStatuses,
} from '@site-mate/dashpivot-shared-library';

import { ITaskDefinitionCreationWeb } from 'app/shared/model/sitemate/task-definition-creation.model';
import { HttpClientService } from 'app/shared/service/http-client.service';

import { AppHierarchy } from '../model/app-hierarchy.enum';

@Injectable({ providedIn: 'root' })
export class TaskService {
  constructor(private readonly httpClientService: HttpClientService) {}

  createTaskDefinitions({
    teamId,
    path,
    taskName,
    templateIds,
    taskType,
    access,
  }: ITaskDefinitionCreationWeb) {
    return this.httpClientService.post<{
      taskDefinitions: ISitemateTaskDefinitionUrl[];
      warnings: ISitemateTaskDefinitionWarning[];
    }>(`teams/${teamId}/template-links`, {
      taskType,
      path,
      taskName,
      templateIds,
      access,
    });
  }

  deleteTaskDefinitions(teamId: string, url: string): Observable<void> {
    return this.httpClientService.remove(`teams/${teamId}/template-links/${encodeURIComponent(url)}`);
  }

  updateTaskDefinitions(
    teamId: string,
    url: string,
    updateParams: ISitemateTaskDefinitionUpdate,
    status?: SitemateRecordStatuses,
  ) {
    let requestUrl = `teams/${teamId}/template-links/${encodeURIComponent(url)}`;

    if (status) {
      requestUrl += `?status=${status}`;
    }

    return this.httpClientService.put<{
      taskDefinitions: ISitemateTaskDefinitionUrl[];
      warnings: ISitemateTaskDefinitionWarning[];
    }>(requestUrl, updateParams);
  }

  getTemplateLinks(teamId: string, status?: SitemateRecordStatuses) {
    const params = status && { status };

    return this.httpClientService.get<ISitemateTaskDefinitionUrl[]>(`teams/${teamId}/template-links`, params);
  }

  getQrCodePoster(teamId: string, taskUrl: string) {
    const encodedTaskUrl = encodeURIComponent(taskUrl);
    return this.httpClientService
      .get(`teams/${teamId}/template-links/${encodedTaskUrl}/poster`, {}, 'blob', 'response')
      .pipe(
        map((response) => {
          return {
            // Reading this value from the X-Pdf-Filename header would require additional server-side changes
            filename: this.httpClientService.getFileNameFromResponseContentDisposition(response),
            body: response.body,
            mimeType: response.headers.get('Content-Type'),
          };
        }),
      );
  }

  getAssignedUsers(hierarchy: AppHierarchy, teamId: string): Observable<ISitemateAssignedUserWithTaskUrls[]> {
    const urlHierarchy = this.getHierarchyUrlPrefix(hierarchy);

    return this.httpClientService.get<ISitemateAssignedUserWithTaskUrls[]>(
      `${urlHierarchy}/${teamId}/assigned-users`,
    );
  }

  removeAssignedUsers(userId: string, hierarchy: AppHierarchy, teamId: string): Observable<void> {
    const urlHierarchy = this.getHierarchyUrlPrefix(hierarchy);

    return this.httpClientService.remove(`${urlHierarchy}/${teamId}/assigned-users`, {
      userId,
    });
  }

  removeSchemeFromUrl(urlToDisplay: string): string {
    const url = new URL(urlToDisplay);
    return `${url.hostname}${url.pathname}`;
  }

  private getHierarchyUrlPrefix(hierarchy: AppHierarchy): string {
    const mapToRequestValue = {
      [AppHierarchy.Organisation]: 'companies',
      [AppHierarchy.Project]: 'projects',
      [AppHierarchy.Team]: 'teams',
    };

    return mapToRequestValue[hierarchy];
  }
}
